import React, { useEffect } from "react"
import SignIn from "../components/Cred/SignIn"
// import LoginView from "../components/Cred/LoginView"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../components/Utils/Loading"

const Enter = () => {
  const { loading, error, data } = useQuery(ENTER_QUERY)
  if (loading) return <Loading />
  if (error) return <SignIn />
  if (data === undefined) return <SignIn />
  // if (error) return <LoginView />
  // if (data === undefined) return <LoginView />

  // return <LoginView />
  return <SignIn />
}

const ENTER_QUERY = gql`
  query {
    me {
      id
      staff
    }
  }
`
export default Enter
